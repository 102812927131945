///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules.
///

///
/// Links
///
@mixin link {
  &:hover {
  }
  .touch & {
  }
}

.link {
  @include link;
}

@mixin mobile-hidden {
  display: none !important;
  @include breakpoint($landscape-up) {
    display: block !important;
  }
}

@mixin hidden {
  display: none !important;
}

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;
  min-width: $size;
  min-height: $size;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    @include triangle($size, $color, $direction);
  }
  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;
    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }
    @if ($direction == up) or ($direction == down) {
      margin-left: -$border-width;
      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;
      @if ($direction == right) {
        margin-left: -$mid-border-width;
      } @else {
        margin-left: -$border-width-and-mid;
      }
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
/// Note: This doesn't work in old browsers (ie. IE), you have to use javascript
/// for them if it's important.
///
@mixin vertical-center {
  @include transform(translateY(-50%));
  top: 50%;
}

@mixin destroy-vertical-center {
  @include transform(none);
  position: static;
  top: auto;
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin button {
  @include font-smoothing;
  @if $typography_cr24 == true {
    @include body-xs-uppercase;
  } @else {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 16px;
  }
  padding: 9px 19px 8px 19px;
  display: inline-block;
  text-align: center;
  min-width: 116px;
  max-width: 100%;
  background-color: $white;
  color: $color-off-black;
  border: 1px solid $color-off-black;
  &:hover,
  &:active {
    cursor: pointer;
    background-color: $color-off-black;
    color: $white;
    text-decoration: none;
    border-color: $white;
  }
  &:active {
    color: $color-gray;
    text-decoration: none;
  }
}

@mixin button2 {
  @include button();
  &--medium {
    @include breakpoint($portrait-up) {
      min-width: 350px;
    }
  }
  &--dark {
    background: $color-off-black;
    color: $white;
    border-color: $white;
    &:hover {
      background: $white;
      color: $color-off-black;
      border-color: $color-off-black;
    }
    &:active {
      color: $color-gray;
    }
  }
  &--inverted {
    background: $white;
    color: $color-off-black;
    border-color: $color-off-black;
    &:hover {
      background: $color-off-black;
      color: $white;
      border-color: $white;
    }
    &:active {
      color: $color-gray;
    }
  }
  &--transparent {
    background: $color-white--transparent;
  }
  &--light {
    background: $color-light-linen;
  }
  // custom cta field selections
  &--clear-dark {
    background: transparent;
    border-color: $color-off-black;
    color: $color-off-black;
  }
  &--clear-light {
    background: transparent;
    border-color: $white;
    color: $white;
    &:hover {
      background: $white;
      color: $color-off-black;
    }
    &:active {
      color: $color-gray;
    }
  }
  &--green {
    background: $color-green-1;
    border-color: $color-green-1;
    color: $white;
    &:hover,
    &:active {
      background: $white;
      color: $color-green-1;
      border-color: $color-green-1;
    }
  }
  &--custom {
    background: transparent;
    border-color: transparent;
    &:hover {
      background: inherit;
      border: inherit;
      color: inherit;
    }
    &:active {
      color: $color-gray;
    }
  }
  &--full {
    width: 100%;
    display: block;
  }
  &--normal-link {
    background: transparent;
    border: none;
    padding: 0;
    text-decoration: underline;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: normal;
    min-width: 0;
    &:hover,
    &:active {
      text-decoration: underline;
      background: none;
      color: $color-gray;
    }
  }
  &--minimal-link {
    background: transparent;
    border: none;
    padding: 0;
    text-transform: none;
    text-decoration: underline;
    font-size: 14px;
    min-width: auto;
    &:hover,
    &:active {
      text-decoration: underline;
      background: none;
      color: $color-dark-gray;
    }
  }
}

@mixin button--inline {
  @include button;
  &.active {
    background: $color-off-black;
    color: $white;
  }
}

@mixin button--spp {
  @if $pdp_modernized_design == true {
    height: 48px;
    padding: 12px 16px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.3;
    font-weight: 500;
    border-color: $color-off-black;
    color: $black;
  } @else {
    min-width: 240px;
    height: 45px;
    line-height: 3;
    padding-top: 0;
    padding-bottom: 0;
  }
  background: $color-off-black;
  color: $white;
  &:hover {
    background: $white;
    color: $color-off-black;
  }
  &:active {
    color: $color-gray;
    border-color: $color-gray;
  }
}

@mixin cta--text-only {
  background: transparent url(/sites/aveda/themes/aveda_base/img/icons/src/caret--cta-right.svg)
    calc(100% - 4px) 50% / 10px no-repeat;
  border: none;
  display: inline-block;
  color: $black;
  font-family: $primary-font-rev; // Remove after $primary-font is set to new FFMeta fonts
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.166;
  min-width: 0;
  &:hover,
  &:active,
  &:visited {
    background-color: transparent;
    color: $black;
  }
  &.cta--inverted {
    color: $white;
    filter: brightness(100);
    &:hover,
    &:active,
    &:visited {
      color: $white;
      filter: brightness(100);
    }
  }
}

// Aveda Sans Typography Mixins
@mixin heading-5xl {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '5xl', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '5xl', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '5xl', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '5xl', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '5xl', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '5xl', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '5xl', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '5xl', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '5xl', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '5xl', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '5xl', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '5xl', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '5xl', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '5xl', 'textDecoration'
    )};
  }
}

@mixin heading-4xl {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '4xl', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '4xl', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '4xl', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '4xl', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '4xl', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '4xl', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '4xl', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '4xl', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '4xl', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '4xl', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '4xl', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '4xl', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '4xl', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '4xl', 'textDecoration'
    )};
  }
}

@mixin heading-3xl {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'textDecoration'
    )};
  }
}

@mixin heading-3xl-sent {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'fontWeight'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '3xl', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'fontWeight'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '3xl', 'textDecoration'
    )};
  }
}

@mixin heading-2xl {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '2xl', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '2xl', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '2xl', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '2xl', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '2xl', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '2xl', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', '2xl', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '2xl', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '2xl', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '2xl', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '2xl', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '2xl', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '2xl', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', '2xl', 'textDecoration'
    )};
  }
}

@mixin heading-xl {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'textDecoration'
    )};
  }
}

@mixin heading-xl-bold {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'letterSpacing'
  )};
  font-weight: 700;
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'xl', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'letterSpacing'
    )};
    font-weight: 700;
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'xl', 'textDecoration'
    )};
  }
}

@mixin heading-lg {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'lg', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'lg', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'lg', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'lg', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'lg', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'lg', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'lg', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'lg', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'lg', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'lg', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'lg', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'lg', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'lg', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'lg', 'textDecoration'
    )};
  }
}

@mixin heading-sm {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'textDecoration'
    )};
  }
}

@mixin heading-sm-bold {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'letterSpacing'
  )};
  font-weight: 700;
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'heading', 'sm', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'letterSpacing'
    )};
    font-weight: 700;
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'heading', 'sm', 'textDecoration'
    )};
  }
}

@mixin body-xl {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '4', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '4', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '4', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '4', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '4', 'normal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '4', 'normal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '4', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '4', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '4', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '4', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '4', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '4', 'normal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '4', 'normal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '4', 'normal', 'textDecoration'
    )};
  }
}

@mixin body-l {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'normal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'normal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'normal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'normal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'normal', 'textDecoration'
    )};
  }
}

@mixin body-l-bold {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'bold', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'bold', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'bold', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'bold', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'bold', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'bold', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '3', 'bold', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'bold', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'bold', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'bold', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'bold', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'bold', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'bold', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '3', 'bold', 'textDecoration'
    )};
  }
}

@mixin body-r {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'normal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'normal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'normal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'normal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'normal', 'textDecoration'
    )};
  }
}

@mixin body-r-bold {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'bold', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'bold', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'bold', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'bold', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'bold', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'bold', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '2', 'bold', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'bold', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'bold', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'bold', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'bold', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'bold', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'bold', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '2', 'bold', 'textDecoration'
    )};
  }
}

@mixin body-xs {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'normal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'normal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'normal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'normal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'normal', 'textDecoration'
    )};
  }
}

@mixin body-xs-bold {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'bold', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'bold', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'bold', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'bold', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'bold', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'bold', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1', 'bold', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'bold', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'bold', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'bold', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'bold', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'bold', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'bold', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1', 'bold', 'textDecoration'
    )};
  }
}

@mixin body-xs-uppercase {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1-uppercase', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1-uppercase', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1-uppercase', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1-uppercase', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1-uppercase', 'normal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1-uppercase', 'normal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'body', '1-uppercase', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1-uppercase', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1-uppercase', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1-uppercase', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1-uppercase', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1-uppercase', 'normal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1-uppercase', 'normal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'body', '1-uppercase', 'normal', 'textDecoration'
    )};
  }
}

@mixin link-1-normal {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'fontWeight'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'fontWeight'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'textDecoration'
    )};
  }
}

@mixin link-1-uppercase {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'normal', 'textDecoration'
    )};
  }
}

@mixin link-1-bold {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'bold', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'bold', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'bold', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'bold', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'bold', 'fontWeight'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '1', 'bold', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'bold', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'bold', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'bold', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'bold', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'bold', 'fontWeight'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '1', 'bold', 'textDecoration'
    )};
  }
}

@mixin link-2-normal {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'normal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'normal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'normal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'normal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'normal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'normal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'normal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'normal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'normal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'normal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'normal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'normal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'normal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'normal', 'textDecoration'
    )};
  }
}

@mixin link-2-bold {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'bold', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'bold', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'bold', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'bold', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'bold', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'bold', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'link', '2', 'bold', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'bold', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'bold', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'bold', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'bold', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'bold', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'bold', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'link', '2', 'bold', 'textDecoration'
    )};
  }
}

@mixin text-disclaimer {
  @include font-smoothing;
  font-family: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'legal', 'fontFamily'
  )};
  line-height: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'legal', 'lineHeight'
  )};
  font-size: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'legal', 'fontSize'
  )};
  letter-spacing: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'legal', 'letterSpacing'
  )};
  font-weight: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'legal', 'fontWeight'
  )};
  text-transform: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'legal', 'textCase'
  )};
  text-decoration: #{map-deep-get(
    $tokens, 'mobile', 'typography', 'legal', 'textDecoration'
  )};
  @include breakpoint($landscape-up) {
    font-family: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'legal', 'fontFamily'
    )};
    line-height: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'legal', 'lineHeight'
    )};
    font-size: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'legal', 'fontSize'
    )};
    letter-spacing: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'legal', 'letterSpacing'
    )};
    font-weight: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'legal', 'fontWeight'
    )};
    text-transform: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'legal', 'textCase'
    )};
    text-decoration: #{map-deep-get(
      $tokens, 'desktop', 'typography', 'legal', 'textDecoration'
    )};
  }
}

// New Typography Mixin
@mixin display--d1 {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 54px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1.03;
  strong {
    font-weight: 500;
  }
  @include breakpoint($landscape-up) {
    font-size: 62px;
  }
}

@mixin display--d2 {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 44px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1.03;
  strong {
    font-weight: 500;
  }
  @include breakpoint($landscape-up) {
    font-size: 54px;
    line-height: 1.05;
  }
}

@mixin headline--h1 {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1.05;
  strong {
    font-weight: 500;
  }
  @include breakpoint($landscape-up) {
    font-size: 44px;
    line-height: 1.06;
  }
}

@mixin headline--h2 {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 28px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 1.06;
  strong {
    font-weight: 500;
  }
  @include breakpoint($landscape-up) {
    font-size: 32px;
    line-height: 1.14;
  }
}

@mixin headline--h3 {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.14;
  strong {
    font-weight: 500;
  }
  @include breakpoint($landscape-up) {
    font-size: 28px;
    line-height: 1.08;
  }
}

@mixin headline--h5 {
  @include font-smoothing;
  font-size: 20px;
  letter-spacing: 0;
  line-height: get-line-height(20px, 24px);
  strong {
    font-weight: 700;
  }
}

@mixin body--large {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.33;
  strong {
    font-weight: 500;
  }
}

@mixin body--regular {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.28;
  strong {
    font-weight: 700;
  }
}

@mixin body--small {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.31;
  strong {
    font-weight: 700;
  }
}

@mixin text--eyebrow {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 1.125;
  text-transform: uppercase;
}

@mixin text--disclaimer {
  @include font-smoothing;
  font-family: $primary-font-rev; // Remove once FF Meta becomes $primary-font
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.25;
}

// Old Typography Mixins
@mixin h1 {
  @include font-smoothing;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 0.89;
  font-weight: 300;
  @include breakpoint($landscape-up) {
    font-size: 62px;
  }
}

@mixin h2 {
  @include font-smoothing;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 0.98;
  @include breakpoint($landscape-up) {
    font-size: 38px;
  }
}

@mixin h3 {
  @include font-smoothing;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.005em;
  line-height: 1.07;
  @include breakpoint($landscape-up) {
    font-size: 24px;
  }
}

@mixin h4 {
  @include font-smoothing;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}

@mixin h13 {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 0 auto 30px auto;
  padding: 0 33px;
  &:before {
    content: '';
    background: $color-off-black;
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 43%;
    left: 0;
  }
  .inner,
  &__inner {
    background: $white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
  @include breakpoint($medium-up) {
    font-size: 14px;
    padding: 0 55px;
  }
}

// Text Styles
@mixin t1 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  @include breakpoint($landscape-up) {
    @include font-smoothing;
    font-size: 17px;
    line-height: 25px;
  }
}

@mixin t2 {
  font-size: 12px;
  line-height: 18px;
  @include breakpoint($landscape-up) {
    font-size: 14px;
    line-height: 22px;
  }
}

@mixin t3 {
  @include font-smoothing;
  font-size: 26px;
  line-height: 31px;
  font-weight: 300;
}

@mixin t4 {
  @include font-smoothing;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

@mixin t5 {
  @include font-smoothing;
  font-weight: normal;
  font-size: 29px;
  line-height: 35px;
}

@mixin text--smaller {
  font-size: 14px;
  line-height: 20px;
}

@mixin text--tiny {
  font-size: 12px;
  line-height: 18px;
}

// Account
@mixin account_page_header {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-size: 41px;
    font-weight: normal;
    margin-bottom: 10px;
    position: relative;
    letter-spacing: -2px;
    bottom: 11px;
    text-align: left;
    padding: 0 0 12px;
  }
}

// Checkout
@mixin button-add-to-bag {
  background: transparent;
  border: 1px solid $color-off-black;
  color: $color-off-black;
  letter-spacing: 0.04em;
  padding: 0 15px;
  height: 36px;
}

@mixin shipping-sub-header() {
  text-transform: uppercase;
  font-size: 14px;
  color: $color-gray;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0 0 5px 0;
}

@mixin checkout-index-header-pc() {
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 8px;
  letter-spacing: -1px;
  font-weight: normal;
}

@mixin checkout-panel--order-summary {
  font-size: 14px;
  margin: 15px 16px;
  @include breakpoint($landscape-up) {
    border-top: 1px solid $color-light-gray;
    margin: 15px 0 35px;
  }
  header {
    @include breakpoint($landscape-up) {
      font-size: 18px;
      margin-bottom: 0;
      padding: 7px 0 4px;
    }
  }
  .label {
    float: left;
    margin-bottom: 1.5em;
    width: 75%;
    .ship-method {
      &__information {
        margin: 10px 0;
        display: inline-block;
      }
    }
    @include breakpoint($landscape-up) {
      text-transform: uppercase;
      width: 85%;
      &.shipping {
        .select-box {
          min-width: 48.3%;
          text-transform: none;
        }
      }
    }
  }
  .total {
    border-top: 1px solid $color-light-gray;
    font-weight: bold;
    margin-bottom: 0;
    padding: 1em 0;
    text-transform: uppercase;
    &.value {
      color: $color-off-black;
    }
    @include breakpoint($landscape-up) {
      color: $color-gray;
      text-transform: uppercase;
    }
  }
  .value {
    color: $color-off-black;
    float: right;
    font-weight: normal;
    text-align: left;
    width: 25%;
    @include breakpoint($landscape-up) {
      color: $color-gray;
      /* padding-left: 4%; */
      width: 15%;
    }
  }
  .points {
    color: $color-gray;
    display: none;
    margin-top: 5px;
    text-transform: capitalize;
  }
}

// Container related width rules
@mixin container-max-width-full {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 100%;
  width: 100%;
}

@mixin container-max-width-landscape {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width-landscape;
  width: 100%;
}

// Container related verical margin rules
@mixin container-vertical-rules($dir: top) {
  .container-vertical-#{$dir}-margin {
    &--large {
      margin-#{$dir}: 60px;
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 96px;
      }
    }
    &--small {
      margin-#{$dir}: 40px;
      @include breakpoint($landscape-up) {
        margin-#{$dir}: 48px;
      }
    }
  }
}

// checkout related mixins

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $primary-font;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(16px, 24px);
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $primary-font;
  font-size: 12px;
  line-height: get-line-height(12px, 18px);
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $primary-font;
  font-size: 14px;
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $primary-font;
  font-size: 12px;
  line-height: get-line-height(12px, 14px);
}

@mixin checkout-body-bold-rev {
  font-family: $primary-font-eng;
  font-size: 14px;
  font-weight: 700;
}
